import moment from "moment";
import { MetricPeriodType } from "~/graphql/generated/graphql";
import { getDateConfigRange, getDateRangeEntries } from "~/helpers/portfolio/portfolio-balance-helpers";
export var useOpexPeriods = function useOpexPeriods(preferences) {
  var details = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var periodType = computed(function () {
    switch (preferences.value.periodRangeType) {
      case "yearWhole":
        return details ? MetricPeriodType.Yearly : MetricPeriodType.Monthly;
      case "yearRolling":
      case "yearToDate":
        return MetricPeriodType.Monthly;
    }
  });
  var dateRange = computed(function () {
    switch (preferences.value.periodRangeType) {
      case "yearWhole":
        return details ? getDateConfigRange({
          customDateStart: undefined,
          customDateEnd: undefined,
          dateRange: "latest5",
          periodType: periodType.value
        }) : getDateConfigRange({
          customDateStart: moment.utc().startOf("year").subtract(1, "y").format("YYYY-MM-DD"),
          customDateEnd: moment.utc().endOf("year").subtract(1, "y").format("YYYY-MM-DD"),
          dateRange: "custom",
          periodType: periodType.value
        });
      case "yearRolling":
        return getDateConfigRange({
          customDateEnd: undefined,
          customDateStart: undefined,
          dateRange: "latest12",
          periodType: periodType.value
        });
      case "yearToDate":
        var start = moment.utc().startOf("year");
        var end = moment.utc().subtract(1, "month");
        if (end.isBefore(start)) end = moment.utc(start);
        return getDateConfigRange({
          customDateStart: start.format("YYYY-MM-DD"),
          customDateEnd: end.format("YYYY-MM-DD"),
          dateRange: "custom",
          periodType: periodType.value
        });
    }
  });
  var offsetDate = computed(function () {
    return function (date) {
      switch (preferences.value.periodRangeType) {
        case "yearWhole":
          return moment.utc(date).add(preferences.value.periodOffset, "y");
        case "yearRolling":
        case "yearToDate":
          return date;
      }
    };
  });
  var from = computed(function () {
    return offsetDate.value(dateRange.value.from);
  });
  var to = computed(function () {
    return offsetDate.value(dateRange.value.to);
  });
  var columns = computed(function () {
    return getDateRangeEntries({
      from: from.value,
      to: to.value
    }, periodType.value);
  });
  return {
    from: from,
    to: to,
    columns: columns,
    periodType: periodType
  };
};