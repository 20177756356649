import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import { getDiff, getDiffPercentage } from "~/helpers/portfolio/portfolio-opex-helpers";
export var useOpexTableHelpers = function useOpexTableHelpers(preferences) {
  var target = computed(function () {
    return function (row, propertyId) {
      var _row$propertiesData$g, _row$propertiesData$g2;
      return (_row$propertiesData$g = (_row$propertiesData$g2 = row.propertiesData.get(propertyId)) === null || _row$propertiesData$g2 === void 0 ? void 0 : _row$propertiesData$g2.resultNormal) !== null && _row$propertiesData$g !== void 0 ? _row$propertiesData$g : 0;
    };
  });
  var benchmark = computed(function () {
    switch (preferences.value.benchmark) {
      case "average.total":
        return function (row) {
          return row.average.resultNormal;
        };
      case "budget.diff":
        return function (row, propertyId) {
          var _row$propertiesData$g3, _row$propertiesData$g4;
          return (_row$propertiesData$g3 = (_row$propertiesData$g4 = row.propertiesData.get(propertyId)) === null || _row$propertiesData$g4 === void 0 ? void 0 : _row$propertiesData$g4.budgetNormal) !== null && _row$propertiesData$g3 !== void 0 ? _row$propertiesData$g3 : 0;
        };
    }
  });
  var diff = computed(function () {
    var _target = target.value;
    var _benchmark = benchmark.value;
    return function (row, propertyId) {
      return getDiff(_target(row, propertyId), _benchmark(row, propertyId));
    };
  });
  var diffPercentage = computed(function () {
    var _target = target.value;
    var _benchmark = benchmark.value;
    return function (row, propertyId) {
      return getDiffPercentage(_target(row, propertyId), _benchmark(row, propertyId));
    };
  });
  var tablePropertyDisplayValue = computed(function () {
    var benchmarkSetting = preferences.value.benchmark;
    return function (row, propertyId) {
      var data = row.propertiesData.get(propertyId);
      if (!data) return 0;
      switch (benchmarkSetting) {
        case "average.total":
          return data.resultNormal;
        case "budget.diff":
          return getDiff(data.resultNormal, data.budgetNormal);
      }
    };
  });
  var tableAverageDisplayValue = computed(function () {
    var benchmarkSetting = preferences.value.benchmark;
    return function (row) {
      switch (benchmarkSetting) {
        case "average.total":
          return row.average.resultNormal;
        case "budget.diff":
          return getDiff(row.average.resultNormal, row.average.budgetNormal);
      }
    };
  });
  var isZeroRow = computed(function () {
    var isZeroValue = function isZeroValue(v) {
      return isNaN(v) || Math.round(v * 1000) / 1000 === 0;
    };
    return function (row) {
      var properties = Array.from(row.propertiesData.values());
      var average = row.average;
      var isZero = isZeroValue(average.budgetNormal) && isZeroValue(average.resultNormal) && properties.every(function (property) {
        return isZeroValue(property.budgetNormal) && isZeroValue(property.resultNormal);
      });
      return isZero;
    };
  });
  return {
    diff: diff,
    diffPercentage: diffPercentage,
    tableAverageDisplayValue: tableAverageDisplayValue,
    tablePropertyDisplayValue: tablePropertyDisplayValue,
    isZeroRow: isZeroRow
  };
};